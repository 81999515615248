import React from 'react';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import GoToButton from '../components/UI/Buttons/GoToButton/GoToButton';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nije pronađeno - Monolit" />
    <div className="container padding-10-1-1">
      <h1>Žao nam je, stranica koju tražite nije pronađena.</h1>
      <p>Izaberite, povratak na naslovnicu ili nas pitajte što vas zanima.</p>
      <div className="flex-row">
        <GoToButton link="/" text="Naslovnica" />
        <GoToButton link="/kontakt" text="Kontaktirajte nas" />
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
